import React from 'react'
import classNames from 'classnames'

import { ReactComponent as Checkmark } from '../../../svg/checkmark.svg'
import { ReactComponent as Close } from '../../../svg/close.svg'
import { ReactComponent as Clock } from '../../../svg/clock.svg'
import { ReactComponent as Question } from '../../../svg/questionmark.svg'

import '../../../scss/react/components/icon.scss'

interface IconProps {
    small?: boolean
}

interface CheckProps extends IconProps {
    checked?: boolean
    question?: boolean
}

export const IconCheck = ({ checked, small, question }: CheckProps) => (
    <span
        className={classNames('icon', 'icon-check', {
            checked,
            'icon-question': question,
            'icon-small': small,
        })}
    >
        {checked && <Checkmark />}
        {question && <Question />}
        {!checked && !question && <Close />}
    </span>
)

export const IconScheduled = ({ small }: CheckProps) => (
    <span
        className={classNames('icon', 'icon-scheduled', {
            'icon-small': small,
        })}
    >
        <Clock />
    </span>
)
