import { Location } from '@zupr/types/fo'
import { formatPaymentMethods } from '@zupr/utils/paymentmethods'

import Trans from '../../../../shared/components/trans'
import GridBlock from '../../../components/grid/block'

interface Props {
    location: Location
}

const LocationPayment = ({ location }: Props) => {
    const hasPaymentMethods = Object.values(
        location.payment_methods || {}
    ).filter((method) => method).length

    const hasOnlinePaymentMethods =
        location.allow_pay_and_collect || location.can_do_delivery

    if (!hasPaymentMethods && !hasOnlinePaymentMethods) {
        return null
    }

    return (
        <GridBlock title="Betalen">
            {hasPaymentMethods && (
                <>
                    <h5>
                        <Trans label="Betaalmogelijkheden in de winkel" />
                    </h5>
                    <p>{formatPaymentMethods(location.payment_methods)}</p>
                </>
            )}
            {hasOnlinePaymentMethods && (
                <>
                    <h5>
                        <Trans label="Betaalmogelijkheden online" />
                    </h5>
                    <p>{formatPaymentMethods({ iDeal: true })}</p>
                </>
            )}
        </GridBlock>
    )
}
export default LocationPayment
