import React from 'react'
import { Location } from '@zupr/types/fo'

import GridBlock from '../../../components/grid/block'
import LinkExternal from '../../../../shared/router/link-external'
import Trans from '../../../../shared/components/trans'
import ReadMore from '../../../../shared/components/read-more'

import SocialLink from '../../../../shared/location/links/social'

interface Props {
    location: Location
}

const SummaryBlock = ({ location }: Props) => (
    <GridBlock title={location.title}>
        <ReadMore text={location.description} />
        <h4>
            <Trans label="Volg ons" dutch />
        </h4>
        <p>
            {location.facebook && (
                <SocialLink
                    platform="facebook"
                    href={location.facebook}
                    location={location}
                />
            )}
            {location.facebook && <br />}
            {location.instagram && (
                <SocialLink
                    platform="instagram"
                    href={location.instagram}
                    location={location}
                />
            )}
            {location.instagram && <br />}

            {location.website && (
                <LinkExternal className="location-link" href={location.website}>
                    <Trans label="Bezoek onze website" dutch />
                </LinkExternal>
            )}
        </p>
    </GridBlock>
)

export default SummaryBlock
