import React from 'react'

import SiteFull from '../../components/site-full'

import Product, { LocationProductProps } from './product'
import Products, { LocationProductsProps } from './products'

import LocationHeader from './header'
import Information from './information'
import Images from './images'

export const LocationProducts = ({
    location,
    ...props
}: LocationProductsProps) => (
    <SiteFull>
        <LocationHeader location={location} />
        <Products location={location} {...props} />
    </SiteFull>
)

export const LocationProduct = ({
    location,
    ...props
}: LocationProductProps) => (
    <SiteFull>
        <LocationHeader location={location} />
        <Product location={location} {...props} />
    </SiteFull>
)

export const LocationInfo = ({ location, ...props }) => (
    <SiteFull>
        <LocationHeader location={location} />
        <Information location={location} {...props} />
    </SiteFull>
)

export const LocationImages = ({ location, ...props }) => (
    <SiteFull>
        <LocationHeader location={location} />
        <Images location={location} {...props} />
    </SiteFull>
)
