import React from 'react'
import { OverlayView } from '@react-google-maps/api'

import '../../../scss/react/map/indicator.scss'

const Indicator = React.memo(({ lat, lng }: google.maps.LatLngLiteral) => (
    <OverlayView
        position={{ lat, lng }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        zIndex={3}
    >
        <div>
            <div className="position-indicator" />
        </div>
    </OverlayView>
))

export default Indicator
