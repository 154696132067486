import { Field } from '@zupr/types/form'
import React, { useCallback } from 'react'

import '../../../../scss/react/form/switch.scss'

interface Props {
    field: Field
    disabled?: boolean
    onChange?: (ev: React.ChangeEventHandler<HTMLInputElement>) => void
}

const SwitchField = ({ field, disabled, onChange }: Props) => {
    const checked = !!field.value

    const handleChange = useCallback(
        (ev) => {
            if (onChange) {
                onChange(ev)
            }
            if (ev.defaultPrevented) return
            if (field.setValue) {
                field.setValue(ev.target.checked)
            }
        },
        [field, onChange]
    )

    const handleToggle = useCallback(
        (ev) => {
            if (disabled) return
            ev.target.checked = !checked
            handleChange(ev)
        },
        [checked, disabled, handleChange]
    )

    return (
        <button className="btn-switch" type="button" onClick={handleToggle}>
            <input
                type="checkbox"
                name={field.name}
                id={field.name}
                checked={checked}
                onChange={handleChange}
                disabled={disabled}
            />
            <label htmlFor={field.name}>
                <div />
            </label>
        </button>
    )
}

export default SwitchField
