import React, { useState } from 'react'
import classnames from 'classnames'

import Trans, { Dutch } from '../../../../shared/components/trans'

import { ReactComponent as Triangle } from '../../../../../svg/triangle.svg'

interface FilterCollapseProps extends Dutch {
    header: string
    children: React.ReactNode
    startCollapsed?: boolean
}

const FilterCollapse = ({
    children,
    header,
    startCollapsed,
}: FilterCollapseProps) => {
    const [collapsed, setCollapsed] = useState<boolean>()

    const isCollapsed =
        typeof collapsed === 'boolean' ? collapsed : !!startCollapsed

    return (
        <div
            className={classnames('sidebar-filter', {
                'filter-collapsed': isCollapsed,
            })}
        >
            <div className="filter-header">
                <button onClick={() => setCollapsed(!isCollapsed)}>
                    {header && typeof header === 'string' && (
                        <Trans label={header} />
                    )}
                    {header && typeof header !== 'string' && header}
                    <Triangle />
                </button>
            </div>
            {!isCollapsed && children}
        </div>
    )
}

export default FilterCollapse
