import React from 'react'

import Trans from '../components/trans'
import ExternalLink from '../router/link-external'

import '../../../scss/react/product/links.scss'

const ProductLinks = ({ product, brand }) => {
    if (!(product && product.website) && !(brand && brand.website)) return null
    return (
        <p className="product-links">
            {product && product.website && (
                <ExternalLink href={product.website}>
                    <Trans label="Visit product page" />
                </ExternalLink>
            )}
            {product && product.website && brand && brand.website && <br />}
            {brand && brand.website && (
                <ExternalLink href={brand.website}>
                    <Trans
                        dutch
                        label="Visit %{brand} page"
                        values={{ brand: brand.title }}
                    />
                </ExternalLink>
            )}
        </p>
    )
}

export default ProductLinks
