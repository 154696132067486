import { t } from '@zupr/i18n'
import { Location } from '@zupr/types/fo'
import qs from 'query-string'
import React, { useCallback, useContext } from 'react'

import RouteContext from '../../../context/route'
import { useShopperLocation } from '../../../context/shopper'
import Tooltip from '../../components/tooltip'
import ExternalLink from '../../router/link-external'

import { ReactComponent as NavigateIcon } from '../../../../svg/navigate-outlined.svg'

import '../../../../scss/react/components/action-links.scss'

const googleMapsHref = (
    {
        address,
        number,
        zipcode,
        city,
        custom_geolocation,
        geo_location,
    }: Location,
    position
) => {
    const parts = [address, number, zipcode, city].filter((part) => !!part)

    let origin
    let destination = parts.join(' ')

    if (custom_geolocation && geo_location) {
        const { lat, lon } = geo_location
        destination = `${lat},${lon}`
    }

    if (position) {
        origin = `${position.lat},${position.lng}`
    }

    return `https://www.google.com/maps/dir/?${qs.stringify({
        api: 1,
        origin,
        destination,
    })}`
}

interface LocationRouteProps {
    location: Location
    className?: string
    children?: React.ReactNode
    iconOnly?: boolean
    noIcon?: boolean
    onClick?: () => void
}

const LocationRoute = ({
    location,
    className,
    children,
    iconOnly,
    noIcon,
    onClick,
}: LocationRouteProps) => {
    const { position } = useShopperLocation()
    const { trackEvent } = useContext(RouteContext)

    const handleClick = useCallback(() => {
        trackEvent({
            id: location.id,
            action: 'clickPlanRoute',
        })

        if (onClick) onClick()
    }, [location.id, onClick, trackEvent])

    return (
        <ExternalLink
            className={className}
            href={googleMapsHref(location, position)}
            onClick={handleClick}
            aria-label={t('Plan route', 'dutch')}
        >
            {iconOnly && <NavigateIcon />}
            {!iconOnly && (
                <>
                    {!noIcon && (
                        <span className="btn-social social-platform">
                            <NavigateIcon />
                        </span>
                    )}
                    {children || <span>{t('Plan route', 'dutch')}</span>}
                </>
            )}
        </ExternalLink>
    )
}

export const RouteAction = ({ ...props }: LocationRouteProps) => (
    <Tooltip label={t('Plan route', 'dutch')}>
        <LocationRoute className="btn btn-small" iconOnly {...props} />
    </Tooltip>
)

export default LocationRoute
