import { ProductLocation } from '@zupr/types/fo'
import { useMemo } from 'react'

const useFeatures = ({
    allow_reservation,
    allow_pay_and_collect,
    stock_prefs,
}: ProductLocation) => {
    return useMemo(
        () => ({
            shoppable: stock_prefs !== 'not_in_stock', // can you put item in shopping list
            reservation: !!allow_reservation,
            payAndCollect: !!allow_pay_and_collect,
        }),
        [allow_pay_and_collect, allow_reservation, stock_prefs]
    )
}

export default useFeatures
