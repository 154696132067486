import React from 'react'

import PaginationOffset from './offset'
import PaginationLimit from './limit'
import PaginationNavigation from './navigation'

interface PaginationListFooterProps {
    count: number
    limit?: number
}

const PaginationListFooter = ({ count, limit }: PaginationListFooterProps) => (
    <React.Fragment>
        <div className="grid-list-pagination grid-list-footer">
            <PaginationOffset count={count} limit={limit} />
            <div className="grid-list-sorters">
                <PaginationLimit limit={limit} />
            </div>
        </div>
        <PaginationNavigation
            count={count}
            limit={limit}
            className="grid-list-footer"
        />
    </React.Fragment>
)

export default PaginationListFooter
