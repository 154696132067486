import classNames from 'classnames'
import React from 'react'

import '../../../../scss/react/frontoffice/grid-page.scss'

interface GridColumnProps {
    swapOrder?: boolean
    children: React.ReactNode
    small?: boolean
}

const GridColumn = ({ swapOrder, small, children }: GridColumnProps) => (
    <div
        className={classNames('grid-column', {
            'grid-column-swap-order': swapOrder,
            'grid-column-small': small,
        })}
    >
        {children}
    </div>
)

export default GridColumn
