import React from 'react'

import Trans from '../components/trans'

const NoResults = ({ term }) => (
    <div className="grid-list-no-results">
        <div className="message">
            {term && (
                <h3>
                    <Trans label="products.no-results" values={{ term }} html />
                </h3>
            )}

            {!term && (
                <h3>
                    <Trans label="Helaas, geen resultaten gevonden" />
                </h3>
            )}
        </div>
    </div>
)

export default NoResults
