import React, { useCallback } from 'react'
import LinkChangeQuery, {
    LinkChangeQueryProps,
} from '../../../../shared/router/link-change-query'

import Checkbox from '../../../../shared/form/fields/checkbox'
import { AggregationBucket } from '@zupr/types/fo'

interface FilterSelectProps extends LinkChangeQueryProps {
    name: string
    checked: boolean
    count?: number
}

export const FilterSelect = ({
    children,
    count,
    name,
    checked,
    ...props
}: FilterSelectProps) => (
    <LinkChangeQuery {...props}>
        <Checkbox
            key={`${name}-${checked}`}
            field={{ name, value: checked, setValue: console.log }}
        />
        {children}
        {!!count && (
            <React.Fragment>
                {' '}
                <small>{`(${count})`}</small>
            </React.Fragment>
        )}
    </LinkChangeQuery>
)
interface FilterMultiselectProps {
    buckets: AggregationBucket[]
    aggregationKey: string
    filterKey: string
    value: string
    formatLabel?: (value: any) => string
    labels?: string[]
}

const FilterMultiselect = ({
    buckets,
    aggregationKey,
    filterKey,
    value,
    formatLabel,
    labels,
}: FilterMultiselectProps) => {
    const values = (value && `${value}`.split(',')) || []

    // if no alternative labels are passed
    // the keys themselves are the labels
    if (!labels) {
        labels = buckets.map(({ key }) => key)
    }

    const toggleValue = (toToggle) => {
        if (values.includes(toToggle)) {
            return values.filter((val) => val !== toToggle)
        }

        return [...values, toToggle]
    }

    const count = useCallback(
        (bucket) => {
            if (bucket[`reverse.${aggregationKey}`])
                return bucket[`reverse.${aggregationKey}`].doc_count
            return bucket.doc_count
        },
        [aggregationKey]
    )

    if (!buckets.length) return null

    return (
        <div className="multiselect-filter">
            {buckets.map((bucket) => (
                <FilterSelect
                    key={`${filterKey}-${bucket.key}`}
                    query={{ [filterKey]: toggleValue(bucket.key).join(',') }}
                    count={count(bucket)}
                    name={filterKey}
                    checked={values.includes(bucket.key)}
                >
                    {formatLabel && formatLabel(bucket.key)}
                    {!formatLabel && bucket.key}
                </FilterSelect>
            ))}
        </div>
    )
}

export default FilterMultiselect
