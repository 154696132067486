export const clothingMaterialGroups = [
    'Main Material',
    'Contrast Fabric 1',
    'Contrast Fabric 2',
    'Outer shell',
    'Lining',
    'Insulation',
    'Trimming',
    'No group',
]

export const footwearMaterialGroups = ['Upper', 'Lining and sock', 'Sole']

export const colors = [
    {
        value: 'white',
        background: '#FFFFFF',
        borderColor: '#A9A9A9',
    },
    {
        value: 'black',
        background: '#000000',
        borderColor: '#111111',
    },
    {
        value: 'grey',
        background: '#C0C0C0',
        borderColor: '#A2A2A2',
    },
    {
        value: 'cream',
        background: '#FFEED6',
        borderColor: '#F9D095',
    },
    {
        value: 'brown',
        background: '#9A6C53',
        borderColor: '#7E523B',
    },
    {
        value: 'purple',
        background: '#8E44AD',
        borderColor: '#8124A8',
    },
    {
        value: 'dark-blue',
        background: '#2586D8',
        borderColor: '#1A6CB1',
    },
    {
        value: 'light-blue',
        background: '#6DB4F0',
        borderColor: '#378ED8',
    },
    {
        value: 'green',
        background: '#43AD4B',
        borderColor: '#0F9146',
    },
    {
        value: 'yellow',
        background: '#F1C40F',
        borderColor: '#CCA401',
    },
    {
        value: 'orange',
        background: '#FF901F',
        borderColor: '#CC741B',
    },
    {
        value: 'red',
        background: '#E74C3C',
        borderColor: '#C93323',
    },
    {
        value: 'pink',
        background: '#F079C7',
        borderColor: '#F24DB7',
    },
    {
        value: 'multicolor',
        borderColor: 'transparent',
    },
]
