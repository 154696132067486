import classNames from 'classnames'
import React from 'react'
import GridBlock from './block'

import '../../../../scss/react/frontoffice/grid-buttons.scss'

interface Props {
    inline?: boolean // is included in block already
    className?: string
    children: React.ReactNode
}

const GridButtons = ({ className, children, inline }: Props) => {
    if (inline) {
        return (
            <div className={classNames('grid-buttons', className)}>
                {children}
            </div>
        )
    }
    return (
        <GridBlock className={classNames('grid-buttons', className)}>
            {children}
        </GridBlock>
    )
}

export default GridButtons
