import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { Image as ImageType, Location } from '@zupr/types/fo'
import { locationUrl } from '@zupr/utils/url'
import Link from 'next/link'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'

import ModalContext from '../../../../context/modal'
import { useIsMobile } from '../../../../context/ux'
import Image from '../../../../shared/components/image'

interface Props {
    image?: ImageType
    alt: string
    className?: string
    style?: {
        width: number
        height: number
    }
}

// use as profile image for blog posts
export const Logo = React.forwardRef<HTMLButtonElement, Props>(
    ({ image, alt, className, style }, ref) => {
        const { openModal } = useContext(ModalContext)

        const handleOverlay = useCallback(() => {
            openModal({
                closeButton: true,
                className: 'modal-image',
                render: () => (
                    <figure>
                        <Image image={image} alt={alt} />
                    </figure>
                ),
            })
        }, [openModal, image, alt])

        return (
            <button
                className="location-logo inline"
                onClick={handleOverlay}
                ref={ref}
            >
                <figure className={className} style={style}>
                    <Image image={image} alt={alt} small />
                </figure>
            </button>
        )
    }
)

interface HeaderLogoProps extends Props {
    location: Location
    offset: number
    image: ImageType
}

const HeaderLogo = ({
    className,
    alt,
    location,
    offset,
    image,
}: HeaderLogoProps) => {
    const isMobile = useIsMobile()

    const [elementPosition, setElementPosition] = useState({ x: 0, y: 520 })
    const ref = useRef<HTMLAnchorElement>()

    useScrollPosition(
        ({ currPos }) => {
            setElementPosition(currPos)
        },
        [],
        ref
    )

    useEffect(() => {
        if (!ref.current) return
        setElementPosition(ref.current.getBoundingClientRect())
    }, [])

    const style = useMemo(() => {
        if (isMobile) return null
        const top = Math.max(elementPosition.y - offset, 0)
        if (top > 116) return null
        return {
            width: 100 - top * -1 - 16,
            height: 100 - top * -1 - 16,
        }
    }, [elementPosition.y, isMobile, offset])

    return (
        <Link href={locationUrl(location, 'home')}>
            <a className="location-logo" ref={ref}>
                <figure className={className} style={style}>
                    <Image image={image} alt={alt} eager />
                </figure>
            </a>
        </Link>
    )
}

export default HeaderLogo
