import NL from 'i18n-iso-countries/langs/nl.json'
import EN from 'i18n-iso-countries/langs/en.json'

const data = {
    NL,
    EN,
}

// these countries are exclude from list
// micronations and other nations without a country flag
const exclude = [
    'AQ',
    'BV',
    'TF',
    'GY',
    'HM',
    'SH',
    'EH',
    'GS',
    'SJ',
]

export const list = Object.keys(NL.countries).filter(country => !exclude.includes(country))

export const getName = (code, lang = 'NL') => {
    const country = data[lang] && data[lang].countries[code]
    if (typeof country === 'string') return country
    if (!country) return
    return country[1] // second name in list is usualy common name
}
