import React from 'react'
import { openToday } from '@zupr/utils/hours'
import Badge from '../../components/badge'

import NowOpen from './nowopen'
import { Location } from '@zupr/types/fo'

interface Props {
    opening_hours: Location['opening_hours']
}

const NowOpenLabel = ({ opening_hours }: Props) => {
    if (!opening_hours) return null
    const today = openToday(opening_hours)

    return (
        <Badge
            success={today.now}
            info={!today.now && today.later}
            warning={!today.now && !today.later}
            className="now-open-badge"
        >
            <NowOpen opening_hours={opening_hours} />
        </Badge>
    )
}

export default NowOpenLabel
