import React from 'react'
import { t } from '@zupr/i18n'
import { ProductLocation } from '@zupr/types/fo'

import { useShoppingListItem } from '../../../../frontoffice/features/shopping-list/hooks/item'

interface Props {
    stock_prefs: ProductLocation['stock_prefs']
    stock_count: ProductLocation['stock_count']
    active?: ProductLocation['active']
}

export const Label = ({ stock_prefs, stock_count, active }: Props) => {
    if (active === false) return t('Discontinued')
    if (stock_prefs === 'in_stock') return t('In stock')
    if (stock_prefs === 'not_in_stock') return t('Not in stock')
    if (stock_prefs === 'call_for_stock') return t('Stock unknown', 'dutch')
    if (stock_prefs === 'exact_stock') {
        if (stock_count <= 0) return t('Not in stock')
        return t('%{stock_count} in stock', { stock_count })
    }
    return t('Stock unknown', 'dutch')
}

export const InteractiveLabel = (productLocation) => {
    const { partOfInformationRequest, isRequesting, isAnswered, stockCount } =
        useShoppingListItem(productLocation)

    if (partOfInformationRequest && isRequesting) {
        return t('Wacht op voorraadstatus...')
    }

    if (partOfInformationRequest && isAnswered) {
        return (
            <Label
                {...productLocation}
                stock_prefs="exact_stock"
                stock_count={stockCount}
            />
        )
    }

    return <Label {...productLocation} />
}

const ProductLocationLabel = (productLocation) => {
    if (productLocation.location)
        return <InteractiveLabel {...productLocation} />
    return <Label {...productLocation} />
}

export default ProductLocationLabel
