import { useList } from '@zupr/hooks/request-redux'
import React, { useMemo, useState } from 'react'

import { FilterSelect } from './multiselect'

import { AggregationBucket } from '@zupr/types/fo'
import Searchbox from '../../../../shared/components/searchbox'
import RemoveFilter from './remove'

export const RemoveSearchFilters = ({ url, selected, filter }) => {
    const [selectedResult] = useList<any>({
        url,
        variables: {
            id__in: selected,
        },
        pause: !selected,
    })

    if (!(selectedResult && selectedResult.count)) return null

    return (
        <>
            {selectedResult.results.map((result) => (
                <RemoveFilter
                    filter={filter}
                    value={selected}
                    removeFromValue={result.id}
                >
                    {result.title}
                </RemoveFilter>
            ))}
        </>
    )
}

interface FilterSearchProps {
    url: string
    selected: string
    filter: string
    searchKey: string
    buckets?: AggregationBucket[]
    aggregationKey?: string
    variables?: any
}

const FilterSearch = ({
    url,
    buckets,
    selected,
    filter,
    searchKey,
    aggregationKey,
    variables,
}: FilterSearchProps) => {
    const [search, setSearch] = useState<string>()

    const selectedIds = useMemo(() => selected?.split(',') || [], [selected])
    const bucketIds = useMemo(
        () => buckets?.map(({ key }) => key) || [],
        [buckets]
    )

    const [selectedResult] = useList<any>({
        url,
        variables: {
            ...variables,
            id__in: selected,
        },
        pause: !selected,
    })

    const [searchResult] = useList<any>({
        url,
        variables: {
            ...variables,
            limit: 7,
            [searchKey]: search,
        },
        pause: !search,
    })

    const [bucketResult] = useList<any>({
        url,
        variables: {
            ...variables,
            id__in: bucketIds.join(','),
        },
        pause: !bucketIds.length,
    })

    // filter out results that are not in selectedIds any more
    const filteredSelected = useMemo(() => {
        return (
            selectedResult?.results?.filter((result) =>
                selectedIds.includes(result.id)
            ) || []
        )
    }, [selectedIds, selectedResult])

    const filteredSearch = useMemo(() => {
        return (
            searchResult?.results?.filter(
                (result) =>
                    !filteredSelected.find(
                        (selected) => selected.id === result.id
                    )
            ) || []
        )
    }, [filteredSelected, searchResult])

    const filteredBucket = useMemo(() => {
        return (
            bucketResult?.results
                ?.filter(
                    (result) =>
                        !filteredSelected.find(
                            (selected) => selected.id === result.id
                        )
                )
                .map((result) => {
                    if (!buckets) return result
                    const bucket = buckets.find(
                        (bucket) => bucket.key === result.id
                    )
                    if (!bucket) return result
                    result.count =
                        (aggregationKey &&
                            bucket[`reverse.${aggregationKey}`] &&
                            bucket[`reverse.${aggregationKey}`].doc_count) ||
                        bucket.doc_count
                    return result
                }) || []
        )
    }, [aggregationKey, bucketResult, buckets, filteredSelected])

    return (
        <React.Fragment>
            <div>
                <Searchbox
                    placeholder={`Search ${filter}`}
                    value={search}
                    onChange={setSearch}
                />
            </div>
            {!!filteredSelected.length && (
                <div className="multiselect-filter">
                    {filteredSelected.map((result) => (
                        <FilterSelect
                            key={result.id}
                            name={result.id}
                            checked
                            query={{
                                [filter]: selectedIds
                                    .filter((id) => id !== result.id)
                                    .join(','),
                            }}
                        >
                            {result.title}
                        </FilterSelect>
                    ))}
                </div>
            )}
            {!!search && !!filteredSearch.length && (
                <div className="multiselect-filter">
                    {filteredSearch.map((result) => (
                        <FilterSelect
                            key={result.id}
                            name={result.id}
                            checked={selectedIds.includes(result.id)}
                            query={{
                                [filter]: [...selectedIds, result.id].join(','),
                            }}
                        >
                            {result.title}
                        </FilterSelect>
                    ))}
                </div>
            )}
            {!search && !!filteredBucket.length && (
                <div className="multiselect-filter">
                    {filteredBucket.map((result) => (
                        <FilterSelect
                            key={result.id}
                            name={result.id}
                            count={result.count}
                            checked={selectedIds.includes(result.id)}
                            query={{
                                [filter]: [...selectedIds, result.id].join(','),
                            }}
                        >
                            {result.title}
                        </FilterSelect>
                    ))}
                </div>
            )}
        </React.Fragment>
    )
}

FilterSearch.defaultProps = {
    searchKey: 'autocomplete',
}

export default FilterSearch
