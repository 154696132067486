import React from 'react'
import { FilterKeys } from '@zupr/types/fo'

import LinkChangeQuery, {
    LinkChangeQueryProps,
} from '../../../../shared/router/link-change-query'

import Trans from '../../../../shared/components/trans'

interface Props extends LinkChangeQueryProps {
    filterKeys: FilterKeys
    query?: Record<string, any>
}

const RemoveAll = ({ filterKeys, query, ...props }: Props) => (
    <LinkChangeQuery
        {...props}
        query={{
            ordering: null,
            ...Object.keys({ ...filterKeys, ordering: true }).reduce(
                (obj, key) => ({ ...obj, [key]: null }),
                {}
            ),
            // sometimes turning off a filter we need to set it to false instead of null
            ...query,
        }}
    >
        <Trans dutch label="Filters wissen" />
    </LinkChangeQuery>
)

export default RemoveAll
