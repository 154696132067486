import React from 'react'
import LinkChangeQuery from '../../../../shared/router/link-change-query'

import { ReactComponent as Clear } from '../../../../../svg/clear.svg'

export const RemoveLink = ({ children, href }) => (
    <LinkChangeQuery href={href} className="clear">
        <Clear />
        {children}
    </LinkChangeQuery>
)

interface Props {
    filter: string
    children: React.ReactNode
    href?: string
    removeFromValue?: string
    value?: string | number
}

const RemoveFilter = ({
    filter,
    children,
    removeFromValue,
    value,
    href,
}: Props) => {
    let newValue = null

    // if value contains multiple values
    // make sure value is a string
    if (value && `${value}`.split(',').length >= 1) {
        newValue = `${value}`
            .split(',')
            .filter((val) => val !== removeFromValue)
    }

    return (
        <LinkChangeQuery
            href={href}
            className="clear"
            query={{
                [filter]: newValue,
            }}
        >
            <Clear />
            {children}
        </LinkChangeQuery>
    )
}

export default RemoveFilter
