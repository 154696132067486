import classnames from 'classnames'
import React, { useState } from 'react'

import Trans from '../../../../shared/components/trans'

import { useIsMobile } from '../../../../context/ux'

import Tray from '../../tray'

import { ReactComponent as Sliders } from '../../../../../svg/sliders.svg'

const FilterButton = ({ className, filterCount, onClick }) => (
    <button
        className={classnames('btn', 'btn-small', 'btn-with-icon', className)}
        onClick={onClick}
    >
        <Sliders />
        <span>
            {!!filterCount && (
                <Trans
                    label="1 filter |||| %{smart_count} filters"
                    values={{ smart_count: filterCount }}
                />
            )}
            {!filterCount && <Trans label="Filteren & sorteren" />}
        </span>
    </button>
)

interface FilterContainer {
    renderFilters: React.ReactNode
    filterCount: number
    resultCount: number
}

const FilterMobile = ({
    renderFilters,
    resultCount,
    filterCount,
}: FilterContainer) => {
    const [trayIsOpen, openTray] = useState(false)

    return (
        <React.Fragment>
            <FilterButton
                className="btn"
                filterCount={filterCount}
                onClick={() => openTray((open) => !open)}
            />

            <Tray sidebar open={trayIsOpen} onClose={() => openTray(false)}>
                <div className="search-filters search-filters-in-tray">
                    <h3>
                        <Trans label="Filters" />
                    </h3>
                    {renderFilters}
                    <button
                        className={classnames('btn  btn-brand', {
                            'btn-loading': resultCount === null,
                        })}
                        onClick={() => openTray(false)}
                    >
                        {resultCount !== null && (
                            <Trans
                                label="Filter %{smart_count} result |||| Filter %{smart_count} results"
                                values={{ smart_count: resultCount }}
                            />
                        )}
                        {resultCount === null && <span className="loading" />}
                    </button>
                </div>
            </Tray>
        </React.Fragment>
    )
}

const Filters = ({
    renderFilters,
    filterCount,
    resultCount,
}: FilterContainer) => {
    const isMobile = useIsMobile()
    return (
        <React.Fragment>
            {isMobile && (
                <FilterMobile
                    renderFilters={renderFilters}
                    filterCount={filterCount}
                    resultCount={resultCount}
                />
            )}
            {!isMobile && <div className="search-filters">{renderFilters}</div>}
        </React.Fragment>
    )
}

export default Filters
