'use client'

import {
    Fragment,
    createElement,
    useCallback,
    useEffect,
    useState,
} from 'react'
import rehypeExternalLinks from 'rehype-external-links'
import rehypeReact from 'rehype-react'
import rehypeTruncate from 'rehype-truncate'
import remarkGFM from 'remark-gfm'
import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'
import remarkStringify from 'remark-stringify'
import strip from 'strip-markdown'
import { unified } from 'unified'

export async function stripMarkdown(source: string): Promise<string> {
    const file = await unified()
        .use(remarkParse)
        .use(remarkGFM)
        .use(strip)
        .use(remarkStringify)
        .process(source)
    return file.toString()
}

export const useStripMarkdown = (source: string): string => {
    const [content, setContent] = useState<string>('')

    const parseSource = useCallback(async (source) => {
        const stripped = await stripMarkdown(source)
        setContent(stripped)
    }, [])

    useEffect(() => {
        parseSource(source)
    }, [parseSource, source])

    return content
}

export const useTruncateMarkdown = (source: string): React.ReactNode => {
    const [content, setContent] = useState(null)

    const parseSource = useCallback(async (source) => {
        // md to html
        const file = await unified()
            .use(remarkParse)
            .use(remarkGFM)
            .use(remarkRehype)
            .use(rehypeExternalLinks, { rel: ['nofollow'], target: '_blank' })
            .use(rehypeTruncate, { maxChars: 200 })
            .use(rehypeReact, {
                createElement,
                Fragment,
            })
            .process(source)

        setContent(file.result)
    }, [])

    useEffect(() => {
        parseSource(source)
    }, [parseSource, source])

    return content
}

export const useMarkdown = (source: string): React.ReactNode => {
    const [content, setContent] = useState(null)

    const parseSource = useCallback(async (source) => {
        // md to html
        const file = await unified()
            .use(remarkParse)
            .use(remarkGFM)
            .use(remarkRehype)
            .use(rehypeExternalLinks, { rel: ['nofollow'], target: '_blank' })
            .use(rehypeReact, {
                createElement,
                Fragment,
            })
            .process(source)

        setContent(file.result)
    }, [])

    useEffect(() => {
        parseSource(source)
    }, [parseSource, source])

    return content
}
