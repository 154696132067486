import { Location } from '@zupr/types/fo'

import GridColumn from '../../../components/grid/column'
import GridPage from '../../../components/grid/page'
import AddressAndLocationBlock from '../blocks/address-and-map'
import ContactBlock from '../blocks/contact'
import LocationDelivery from '../blocks/delivery'
import LocationPayment from '../blocks/payment'
import SummaryBlock from '../blocks/summary'

import '../../../../../scss/react/pages/location-view.scss'

interface Props {
    location: Location
}

const LocationInformation = ({ location }: Props) => (
    <GridPage leftWide className="location-view">
        <GridColumn swapOrder>
            <SummaryBlock location={location} />
            <ContactBlock location={location} />
        </GridColumn>
        <GridColumn>
            <AddressAndLocationBlock wide location={location} />
            <LocationPayment location={location} />
            <LocationDelivery location={location} />
        </GridColumn>
    </GridPage>
)

export default LocationInformation
