import React, { useState, useMemo, useCallback } from 'react'
import InputRange, { Range } from 'react-input-range'
import { t } from '@zupr/i18n'

import { PriceOnly } from '../../../../shared/form/fields/price-and-currency'

import '../../../../../scss/react/filter/price.scss'

interface Props {
    buckets: any
    filterKey: any
    aggregationKey: any
    onChange: any
    value: Range
    step?: number
    formatLabel?: string
}

const PriceFilter = ({
    step,
    buckets,
    filterKey,
    aggregationKey,
    onChange,
    formatLabel,
    ...props
}: Props) => {
    const [valueWhileEditing, setValue] = useState<Range>() // input range doesnt have local state

    const maxValue = buckets.reduce(
        (max, bucket) => Math.max(max, bucket.key + 100),
        0
    )

    const minValue = buckets.reduce(
        (min, bucket) => Math.min(min, bucket.key),
        buckets[0].key
    )

    const value = useMemo(
        () => ({
            min: valueWhileEditing?.min || props.value?.min || minValue,
            max: valueWhileEditing?.max || props.value?.max || maxValue,
        }),
        [maxValue, minValue, props.value, valueWhileEditing]
    )

    const fieldMin = useMemo(() => {
        return {
            value: (value && parseInt(value.min)) || minValue,
            setValue: (min) => {
                setValue({ ...value, min })
            },
        }
    }, [minValue, value])

    const fieldMax = useMemo(() => {
        return {
            value: (value && parseInt(value.max)) || maxValue,
            setValue: (max) => {
                setValue({ ...value, max })
            },
        }
    }, [maxValue, value])

    const handleBlur = useCallback(() => onChange(value), [onChange, value])

    return (
        <div className="price-filter">
            <div className="price-filter-inputs">
                <PriceOnly small field={fieldMin} onBlur={handleBlur} />
                <span>{t('tot', 'dutch')}</span>
                <PriceOnly small field={fieldMax} onBlur={handleBlur} />
            </div>
            <InputRange
                step={step}
                minValue={minValue}
                maxValue={maxValue}
                value={value || { min: minValue, max: maxValue }}
                onChange={(range: Range) => setValue(range)}
                onChangeComplete={onChange}
            />
        </div>
    )
}

export default PriceFilter
