import { Category } from '@zupr/types/fo'
import Link from 'next/link'

import { ReactComponent as Chevron } from '../../../../../svg/chevron.svg'
import LinkChangeQuery from '../../../../shared/router/link-change-query'
import CollapsableFilter from '../../../components/search/filter/collapse'
import { FilterLink } from '../../../components/search/filter/link'
interface Props {
    categoryBreadcrumbs: Category[]
    categories: Category[]
    activeCategory: Category
    baseUrl: string
    useSlugs: boolean
}

const ProductsCategories = ({
    categories,
    activeCategory,
    categoryBreadcrumbs,
    baseUrl,
    useSlugs,
}: Props) => {
    // get the parent category
    const parent =
        categoryBreadcrumbs?.length > 1 &&
        categoryBreadcrumbs[categoryBreadcrumbs?.length - 2]

    return (
        <CollapsableFilter header="Categories">
            {activeCategory && useSlugs && (
                <Link href={parent ? `${baseUrl}/${parent.slug}` : baseUrl}>
                    <a className="filter-back">
                        <Chevron />
                        {activeCategory.name}
                    </a>
                </Link>
            )}

            {activeCategory && !useSlugs && (
                <LinkChangeQuery
                    query={{
                        category: parent?.slug || null,
                    }}
                    className="filter-back"
                >
                    <Chevron />
                    {activeCategory.name}
                </LinkChangeQuery>
            )}

            {categories.map(({ slug, name, productCount }) =>
                useSlugs ? (
                    <FilterLink
                        key={slug}
                        href={`${baseUrl}/${slug}`}
                        count={productCount}
                    >
                        {name}
                    </FilterLink>
                ) : (
                    <FilterLink
                        key={slug}
                        query={{ category: slug }}
                        count={productCount}
                    >
                        {name}
                    </FilterLink>
                )
            )}
        </CollapsableFilter>
    )
}

export default ProductsCategories
