import {
    DirectionsRenderer,
    GoogleMap,
    useLoadScript,
} from '@react-google-maps/api'
import * as Sentry from '@sentry/nextjs'
import { getBoundsZoomLevel } from '@zupr/utils/geolocation'
import { config } from '@zupr/utils/googlemaps'
import classNames from 'classnames'
import debounce from 'lodash/debounce'
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill'

import DomainContext from '../../context/domain'
import GeolocationContext from '../../context/geolocation'
import { useShopperLocation } from '../../context/shopper'
import ErrorBoundary from '../error/boundary'
import Indicator from './indicator'
import styleDefault from './styles/default.json'
import styleSimplified from './styles/simplified.json'

import '../../../scss/react/map/map.scss'

export const Loader = ({ children, renderLoading }) => {
    const { isGmLoaded, setGmLoaded } = useContext(GeolocationContext)
    const { isLoaded, loadError } = useLoadScript(config)

    useEffect(() => {
        if (!loadError) return
        Sentry.captureMessage(JSON.stringify(loadError))
    }, [loadError])

    useEffect(() => {
        if (!isLoaded) return
        if (isGmLoaded) return
        setGmLoaded(true)
    }, [isGmLoaded, isLoaded, setGmLoaded])

    if (!isLoaded) return <React.Fragment>{renderLoading}</React.Fragment>
    return <ErrorBoundary>{children}</ErrorBoundary>
}

Loader.defaultProps = {
    renderLoading: <div className="map-loader" />,
}

interface Props {
    onMapLoad?: (map: google.maps.Map) => void
    onDragEnd?: (map: google.maps.Map) => void
    directions?: google.maps.DirectionsResult
    preserveViewport?: boolean
    debounceWait?: number
    options?: google.maps.MapOptions
    center?: google.maps.LatLngLiteral
    zoom?: number
    simplified?: boolean
    className?: string
    children?: React.ReactNode
    style?: React.CSSProperties
}

interface GoogleMapsProps extends Props {
    dimension: {
        width: number
        height: number
    }
}

const GoogleMaps = ({
    dimension,
    onMapLoad,
    onDragEnd,
    directions,
    preserveViewport,
    debounceWait,
    options,
    center,
    zoom,
    simplified,
    children,
    ...rest
}: GoogleMapsProps) => {
    const [map, setMap] = useState<google.maps.Map>()

    const { setBounds, viewport, ...context } = useContext(GeolocationContext)
    const { position } = useShopperLocation()

    const { data } = useContext(DomainContext)

    const handleBoundsChange = () => {
        if (!map) return

        const bounds = map.getBounds()
        const center = map.getCenter()

        if (bounds) {
            setBounds({
                gBounds: bounds,
                center: {
                    lat: center.lat(),
                    lng: center.lng(),
                },
                zoom: map.getZoom(),
            })
        }
    }

    // change map style
    useEffect(() => {
        if (!map) return
        // set custom style
        const zuprStyle = new window.google.maps.StyledMapType(
            simplified ? styleSimplified : styleDefault,
            {
                name: 'Map',
            }
        )
        map.mapTypes.set('zuprStyle', zuprStyle)
        map.setMapTypeId('zuprStyle')
    }, [simplified, map])

    const onLoad = useCallback(
        (mapInstance) => {
            setMap(mapInstance)
            if (onMapLoad) onMapLoad(mapInstance)

            // prevent infowindows that are not ours
            // Here we redefine set() method.
            // If it is called for map option, we hide InfoWindow unless it is renderen by react
            const set = window.google.maps.InfoWindow.prototype.set
            window.google.maps.InfoWindow.prototype.set = function (key) {
                if (key === 'map' && this.content instanceof HTMLElement) {
                    if (this.content.attributes.jstcache) {
                        return
                    }
                }
                set.apply(this, arguments)
            }
        },
        [onMapLoad]
    )

    const handleDragEnd = useCallback(() => {
        onDragEnd && onDragEnd(map)
    }, [map, onDragEnd])

    const handleBoundsChangeDebounced = useCallback(
        () => debounce(handleBoundsChange, debounceWait),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [debounceWait, handleBoundsChange, map, context] // update the callback when context is updated
    )

    let renderCenter: google.maps.LatLngLiteral = center || context.center // get from props

    // only zoom into viewport when no directions are rendered
    const renderZoom = useMemo(() => {
        if (!map) return
        if (zoom) return zoom
        if (viewport && !directions) {
            return getBoundsZoomLevel(viewport, dimension)
        }
        return context.zoom
    }, [context.zoom, dimension, directions, map, viewport, zoom])

    useEffect(() => {
        if (map && window.google && dimension.width && dimension.height) {
            window.google.maps.event.trigger(map, 'resize')
        }
    }, [map, dimension.width, dimension.height])

    if (!renderCenter) return null

    return (
        <GoogleMap
            mapContainerStyle={{
                height: '100%',
                width: '100%',
            }}
            onLoad={onLoad}
            options={{
                disableDefaultUI: true,
                mapTypeControl: false,
                zoomControl: true,
                zoomControlOptions: {
                    position:
                        (window.google &&
                            window.google.maps.ControlPosition.LEFT_BOTTOM) ||
                        6,
                },
                streetViewControl: false,
                gestureHandling: 'cooperative',
                ...options,
            }}
            onBoundsChanged={handleBoundsChangeDebounced}
            center={renderCenter}
            zoom={renderZoom}
            {...rest}
            onDragEnd={handleDragEnd}
        >
            {children}
            {map && position && <Indicator {...position} />}

            {directions && (
                <DirectionsRenderer
                    directions={directions}
                    options={{
                        suppressMarkers: true,
                        preserveViewport,
                        polylineOptions: {
                            strokeColor: data?.directionsColor || '#2586d8',
                            strokeWeight: 6,
                            strokeOpacity: 0.75,
                        },
                    }}
                />
            )}
        </GoogleMap>
    )
}

const GooglemapsLoader = ({ className, children, style, ...props }: Props) => {
    const { width, height, ref } = useResizeDetector({
        refreshMode: 'debounce',
        refreshRate: 333,
    })
    return (
        <div
            style={style}
            className={classNames('google-maps', className || 'map')}
            ref={ref}
        >
            <ErrorBoundary>
                <Loader>
                    {!!(width && height) && (
                        <GoogleMaps dimension={{ width, height }} {...props}>
                            {children}
                        </GoogleMaps>
                    )}
                </Loader>
            </ErrorBoundary>
        </div>
    )
}

export default GooglemapsLoader
