import classnames from 'classnames'
import React from 'react'

import '../../../../scss/react/frontoffice/grid-page.scss'

interface GridPageProps {
    className?: string
    leftWide?: boolean
    rightWide?: boolean
    centered?: boolean
    children: React.ReactNode
}

const GridPage = ({
    className,
    leftWide,
    rightWide,
    children,
    centered,
}: GridPageProps) => {
    return (
        <div
            className={classnames('grid-page', className, {
                'grid-page-left-wide': leftWide,
                'grid-page-right-wide': rightWide,
                'grid-page-centered': centered,
            })}
        >
            {children}
        </div>
    )
}

export default GridPage
