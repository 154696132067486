import { Location } from '@zupr/types/fo'
import { locationUrl } from '@zupr/utils/url'

import Share from '../../components/share'

import { EmailAction } from './email'
import { PhoneAction } from './phone'
import { RouteAction } from './route'
import { WebsiteAction } from './website'

import '../../../../scss/react/components/action-links.scss'

interface ActionLinksProps {
    location: Location
    share?: boolean
}

const ActionLinks = ({ location, share }: ActionLinksProps) => {
    return (
        <div className="location-action-links">
            {location.phone && <PhoneAction location={location} />}
            {location.email && <EmailAction location={location} />}
            {location.website && <WebsiteAction location={location} />}
            <RouteAction location={location} />
            {share && (
                <Share
                    className="btn btn-small"
                    url={locationUrl(location, 'home')}
                />
            )}
        </div>
    )
}

export default ActionLinks
