import { t } from '@zupr/i18n'
import classNames from 'classnames'

import { IconCheck } from '../../../shared/components/icon'
import Trans from '../../../shared/components/trans'
import GridBlock from './block'
import GridButtons from './buttons'
import GridSummary from './summary'

import '../../../../scss/react/frontoffice/grid-form.scss'

export const GridCheck = ({
    children,
    checked,
    question,
}: {
    children: React.ReactNode
    checked?: boolean
    question?: boolean
}) => (
    <div className="grid-check">
        <IconCheck checked={checked} question={question} />
        <span>{children}</span>
    </div>
)

interface Props {
    onEdit?: () => void
    onConfirm?: () => void
    confirmed?: boolean
    future?: boolean
    title?: string
    children: React.ReactNode
    valid?: boolean
    summary?: string | string[] | React.ReactNode
    inactive?: boolean
}

const GridFormBlock = ({
    children,
    title,
    valid,
    confirmed,
    summary,
    onEdit,
    onConfirm,
    inactive,
    future,
}: Props) => (
    <GridBlock
        className={classNames('grid-form', {
            'grid-form-future': future,
        })}
    >
        {!!title && (
            <h4>
                {t(title)}
                {confirmed && (
                    <button className="inline" onClick={onEdit}>
                        <Trans label="Edit" />
                    </button>
                )}
            </h4>
        )}
        {!inactive && !future && (
            <>
                {!confirmed && children}
                {confirmed && typeof summary === 'string' && (
                    <GridSummary items={[{ label: summary, success: true }]} />
                )}
                {confirmed && Array.isArray(summary) && (
                    <GridSummary
                        items={summary
                            .filter((item) => !(!item || item === ''))
                            .map((item) => ({
                                label: item,
                                success: true,
                            }))}
                    />
                )}
                {confirmed &&
                    typeof summary !== 'string' &&
                    !Array.isArray(summary) &&
                    summary}
                {!confirmed && !!onConfirm && (
                    <GridButtons inline>
                        <button
                            className="btn btn-success"
                            disabled={!valid}
                            onClick={onConfirm}
                        >
                            <Trans label="Ga verder" />
                        </button>
                    </GridButtons>
                )}
            </>
        )}
    </GridBlock>
)

export default GridFormBlock
