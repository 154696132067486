import React from 'react'
import Link from 'next/link'
import { Category, Classification } from '@zupr/types/fo'

import GridBlock from './block'

import '../../../../scss/react/frontoffice/grid-breadcrumbs.scss'
interface CategoryBreadcrumbsProps {
    baseUrl: string
    categories: Category[]
    useSlugs?: boolean
}

export const CategoryBreadcrumbs = ({
    baseUrl,
    categories,
    useSlugs,
}: CategoryBreadcrumbsProps) => {
    if (!categories) return null
    if (categories.length < 1) return null
    const layers = categories.slice(-4)
    return (
        <>
            {layers.map((category, i) => (
                <React.Fragment key={category.slug}>
                    {useSlugs && (
                        <Link href={`${baseUrl}/${category.slug}`}>
                            <a>
                                {(i === 0 && layers.length > 3 && '..') ||
                                    category.name}
                            </a>
                        </Link>
                    )}
                    {!useSlugs && (
                        <Link href={`${baseUrl}?category=${category.slug}`}>
                            <a>
                                {(i === 0 && layers.length > 3 && '..') ||
                                    category.name}
                            </a>
                        </Link>
                    )}
                    {i + 1 !== layers.length && <span>{'/'}</span>}
                </React.Fragment>
            ))}
        </>
    )
}

interface ClassificationBreadcrumbsProps {
    baseUrl: string
    classifications: Classification[]
}

export const ClassificationBreadcrumbs = ({
    baseUrl,
    classifications,
}: ClassificationBreadcrumbsProps) => {
    if (!classifications) return null
    if (classifications.length < 1) return null
    const layers = classifications.slice(-4)
    return (
        <>
            {layers.map((classification, i) => (
                <React.Fragment key={classification.slug}>
                    <Link
                        href={`${baseUrl}?classification=${classification.slug}`}
                    >
                        <a>
                            {(i === 0 && layers.length > 3 && '..') ||
                                classification.name}
                        </a>
                    </Link>
                    {i + 1 !== layers.length && <span>{'/'}</span>}
                </React.Fragment>
            ))}
        </>
    )
}

const GridBreadcrumbs = ({ children }) => (
    <GridBlock fullWidth className="grid-breadcrumbs">
        {children}
    </GridBlock>
)

export default GridBreadcrumbs
