import { Location } from '@zupr/types/fo'
import classNames from 'classnames'

import Trans from '../../../../shared/components/trans'
import Address from '../../../../shared/location/address'
import OpeningHours from '../../../../shared/location/hours/table'
import RouteLink from '../../../../shared/location/links/route'
import { LocationMap } from '../../../../shared/location/map'
import GridBlock from '../../../components/grid/block'

import '../../../../../scss/react/pages/location-view.scss'

interface AddressAndLocationProps {
    location: Location
    wide?: boolean
}

const AddressAndLocationBlock = ({
    location,
    wide,
}: AddressAndLocationProps) => (
    <GridBlock title="Adres & openingstijden">
        <div className={classNames('address-and-map-block', { wide })}>
            <LocationMap position={location.geo_location} />
            <div>
                <h4>
                    <Trans label="Adres" dutch />
                </h4>
                <p>
                    <Address {...location} /> <br />
                    <RouteLink className="location-link" location={location} />
                </p>
                <h4>
                    <Trans label="Openingstijden" dutch />
                </h4>
                <OpeningHours hours={location.opening_hours} />
            </div>
        </div>
    </GridBlock>
)

export default AddressAndLocationBlock
