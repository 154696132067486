import { t } from '@zupr/i18n'
import classNames from 'classnames'
import { useRouter } from 'next/router'

import { useIsMobile } from '../../../context/ux'
import Link from '../../router/link-change-query'
import PageSelector from './page-selector'
import Pages from './pages'

import { ReactComponent as Chevron } from '../../../../svg/chevron.svg'

import '../../../../scss/react/components/pagination.scss'

interface Props {
    limit?: number
    count: number
    className?: string
    offsetKey?: string
}

const Navigation = ({ count, limit, className, offsetKey }: Props) => {
    const isMobile = useIsMobile()
    const { query } = useRouter()

    offsetKey = offsetKey || 'offset'

    const offset =
        // offset never negative or larger than list count
        Math.min(Math.max(Number(query[offsetKey]), 0), count) ||
        // if we know nothing
        0

    limit = Math.max(Number(query.limit), 0) || limit || 24

    const pages = (count && Math.ceil(count / limit)) || 0

    if (pages <= 1) return null

    const hasNext = offset + limit < count
    const hasPrev = offset - limit >= 0
    const currentPage = Math.ceil(offset / limit)

    return (
        <div className={classNames('pagination-navigation', className)}>
            {!hasPrev && (
                <span className="previous">
                    <Chevron />
                </span>
            )}

            {!!hasPrev && (
                <Link
                    className="previous"
                    aria-label={t('Previous')}
                    query={{ [offsetKey]: offset - limit || null }}
                >
                    <Chevron />
                </Link>
            )}

            {!isMobile && pages && (
                <Pages
                    currentPage={currentPage}
                    offsetKey={offsetKey}
                    pages={pages}
                    limit={limit}
                />
            )}

            {isMobile && (
                <PageSelector
                    currentPage={currentPage}
                    pages={pages}
                    limit={limit}
                    offsetKey={offsetKey}
                />
            )}

            {!!hasNext && (
                <Link
                    className="next"
                    aria-label={t('Next')}
                    query={{ [offsetKey]: offset + limit }}
                >
                    <Chevron />
                </Link>
            )}

            {!hasNext && (
                <span className="next">
                    <Chevron />
                </span>
            )}
        </div>
    )
}

export default Navigation
