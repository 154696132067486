import React from 'react'
import GridBlockDetail from './detail'

interface Props {
    children: React.ReactNode
}

const GridBlockNotification = ({ children }: Props) => (
    <GridBlockDetail center>{children}</GridBlockDetail>
)

export default GridBlockNotification
