import { useCallback, useMemo } from 'react'
import Trans from '../../../../shared/components/trans'
import { FilterSelect } from '../../../components/search/filter/multiselect'

const StockFilters = ({ query }) => {
    const stock = useMemo(
        () =>
            query?.stock?.split(',') || [
                'in_stock',
                'call_for_stock',
                'not_in_stock',
            ],
        [query?.stock]
    )

    const showProductsWithoutImages = useMemo(
        () => query?.['images'] === 'all',
        [query]
    )

    const remove = useCallback(
        (key: string) => {
            return stock.filter((option) => option !== key).join(',')
        },
        [stock]
    )

    const add = useCallback(
        (key: string) => {
            return [...stock, key].join(',')
        },
        [stock]
    )

    return (
        <div className="sidebar-filter">
            <div className="filter-header">
                <label htmlFor="stock">
                    <Trans label="Toon producten" />
                </label>
            </div>
            <div className="multiselect-filter">
                <FilterSelect
                    query={{
                        stock: stock.includes('in_stock')
                            ? remove('in_stock')
                            : add('in_stock'),
                    }}
                    name="stock"
                    checked={stock.includes('in_stock')}
                >
                    <Trans label="In stock" />
                </FilterSelect>
                <FilterSelect
                    query={{
                        stock: stock.includes('call_for_stock')
                            ? remove('call_for_stock')
                            : add('call_for_stock'),
                    }}
                    name="stock"
                    checked={stock.includes('call_for_stock')}
                >
                    <Trans label="Voorraad niet bekend" />
                </FilterSelect>
                <FilterSelect
                    query={{
                        stock: stock.includes('not_in_stock')
                            ? remove('not_in_stock')
                            : add('not_in_stock'),
                    }}
                    name="stock"
                    checked={stock.includes('not_in_stock')}
                >
                    <Trans label="Niet op voorraad" />
                </FilterSelect>
                <FilterSelect
                    query={{
                        images: showProductsWithoutImages ? null : 'all',
                    }}
                    name="stock"
                    checked={query.images === 'all'}
                >
                    <Trans label="Zonder afbeeldingen" />
                </FilterSelect>
            </div>
        </div>
    )
}

export default StockFilters
