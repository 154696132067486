import { t } from '@zupr/i18n'
import classnames from 'classnames'
import ReactSelect, { components } from 'react-select'

import ReactSelectAsync from 'react-select/async'
import ReactSelectAsyncCreatable from 'react-select/async-creatable'

import { ReactComponent as Close } from '../../../svg/close.svg'
import { ReactComponent as Triangles } from '../../../svg/select.svg'

import '../../../scss/react/components/select.scss'

export const Option = components.Option as any
export const SingleValue = components.SingleValue as any
export const MultiValueLabel = components.MultiValueLabel as any

const $grey = '#a0abb4'
const $white = '#FFF'
const $darkGrey = '#474e53'
const $lightGrey = '#d8dee4'
const $lighter = '#f8f9fd'
const $light = '#f2f3f7'
const $inputRadius = '4px'

const $boxShadow = '0 2px 4px 0 rgba(0,0,0,0.09)'

const findOptionForValue = (value, options) => {
    if (Array.isArray(value)) {
        return value.map((val) => findOptionForValue(val, options))
    }

    const option = options && options.find((option) => option.value === value)

    if (option) {
        return option
    }

    if (typeof value === 'string' || typeof value === 'number') {
        return { value, label: value }
    }

    return value
}

const zuprStyles = ({ isSmall }) => ({
    control: (base, { isDisabled }) => ({
        ...base,
        borderColor: $lightGrey,
        backgroundColor: $white,
        borderRadius: $inputRadius,
        boxShadow: 'none',
        '&:hover': {
            borderColor: $grey,
        },
        opacity: isDisabled ? 0.5 : 1,
    }),

    valueContainer: (base, { isMulti }) => ({
        ...base,
        paddingTop: isSmall ? 0 : 4,
        paddingBottom: isSmall ? 0 : 4,
        paddingLeft: isSmall ? 8 : isMulti ? 15 : 16,
        paddingRight: 0,
        lineHeight: '24px',
    }),

    singleValue: (base) => ({
        ...base,
        color: $darkGrey,
    }),

    placeholder: (base) => ({
        ...base,
        color: $grey,
        whiteSpace: 'nowrap',
        padding: 0,
    }),

    input: (base) => ({
        ...base,
        color: $darkGrey,
        paddingBottom: 0,
        paddingTop: 0,
        paddingLeft: isSmall ? 0 : 0,
        height: isSmall ? '18px' : '34px',
    }),

    menuPortal: (base) => ({
        ...base,
        zIndex: 4,
    }),

    menu: (base) => {
        return {
            ...base,
            boxShadow: $boxShadow,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: $grey,
            borderRadius: $inputRadius,
            marginTop: 4,
        }
    },

    menuList: (base) => ({
        ...base,
        paddingBottom: 0,
        paddingTop: 0,
        borderRadius: $inputRadius,
    }),

    option: (base, { isFocused, isDisabled }) => ({
        ...base,
        color: isDisabled ? $lightGrey : $darkGrey,
        backgroundColor: isFocused ? $lighter : 'transparent',
        fontWeight: 400,
        borderColor: $lightGrey,
        borderStyle: 'solid',
        borderWidth: 0,
        borderTopWidth: 0,
        padding: '8px 14px',
        lineHeight: '20px',
    }),

    multiValue: (base) => ({
        ...base,
        borderRadius: $inputRadius,
        height: 24,
        backgroundColor: $light,
        borderColor: $lightGrey,
        borderStyle: 'solid',
        borderWidth: 1,
        fontWeight: '500',
        margin: '3px',
    }),

    multiValueLabel: (base) => ({
        ...base,
        fontSize: '14px',
        margin: '2px 0 2px 12px',
        color: $darkGrey,
        lineHeight: '18px',
        borderRadius: '0',
        padding: '0',
        paddingLeft: '0',
    }),

    multiValueRemove: (base) => ({
        ...base,
        cursor: 'pointer',
        opacity: 0.5,
        margin: '0 8px',
        padding: '0',
        ':hover': {
            opacity: 1,
            cursor: 'pointer',
        },
    }),

    dropdownIndicator: (base) => ({
        ...base,
        padding: '11px 14px',
    }),

    noOptionsMessage: (base) => ({
        ...base,
        padding: '14px 0',
        color: $grey,
    }),

    indicatorSeparator: (
        base,
        { hasValue, selectProps: { isClearable, isMulti, isLoading } }
    ) => ({
        ...base,
        width: (hasValue && (isClearable || isMulti)) || isLoading ? 1 : 0,
        display: 'none',
        backgroundColor: $lightGrey,
        opacity: 0.5,
        margin: '6px 0',
    }),

    clearIndicator: (base) => ({
        ...base,
        opacity: 0.5,
        padding: '0 0 0 14px',
        ':hover': {
            opacity: 1,
            cursor: 'pointer',
        },
    }),
})

const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
        <Triangles style={{ width: 6, height: 10, fill: $darkGrey }} />
    </components.DropdownIndicator>
)

const ClearIndicator = (props) => (
    <components.ClearIndicator {...props}>
        <Close style={{ width: 10, height: 10, fill: $grey }} />
    </components.ClearIndicator>
)

const MultiValueRemove = (props) => (
    <components.MultiValueRemove {...props}>
        <Close style={{ width: 8, height: 8, fill: $grey }} />
    </components.MultiValueRemove>
)

const defaultProps = {
    minMenuHeight: 30 * 2,
    maxMenuHeight: 30 * 6,
    classNamePrefix: 'react-select',
    portalPlacement: 'auto',
    menuShouldBlockScroll: true,
}

const Select = ({
    value,
    components,
    styles,
    className,
    options,
    isSmall,
    name,
    ...props
}: any) => (
    <ReactSelect
        {...defaultProps}
        menuPortalTarget={document && document.body}
        className={classnames('react-select', className)}
        aria-label={name}
        placeholder={t('form.autocomplete.select')}
        noOptionsMessage={() => t('form.autocomplete.no-results')}
        styles={{
            ...zuprStyles({ isSmall }),
            ...styles,
        }}
        {...props}
        value={findOptionForValue(value, options)}
        options={options}
        components={{
            MultiValueRemove,
            DropdownIndicator,
            ClearIndicator,
            ...components,
        }}
        isDisabled={props.disabled || props.isDisabled}
        isOptionDisabled={(option: any) => !!option.disabled}
    />
)

export const SelectAsync = ({
    value,
    components,
    styles,
    className,
    options,
    isSmall,
    name,
    ...props
}: any) => (
    <ReactSelectAsync
        {...defaultProps}
        menuPortalTarget={document && document.body}
        cacheOptions
        className={classnames('react-select', className)}
        aria-label={name}
        noOptionsMessage={() => t('form.autocomplete.no-results')}
        styles={{
            ...zuprStyles({ isSmall }),
            ...styles,
        }}
        {...props}
        value={findOptionForValue(value, options)}
        options={options}
        components={{
            MultiValueRemove,
            DropdownIndicator,
            ClearIndicator,
            ...components,
        }}
    />
)

export const SelectAsyncCreatable = ({
    value,
    components,
    styles,
    className,
    options,
    isSmall,
    name,
    ...props
}: any) => (
    <ReactSelectAsyncCreatable
        {...defaultProps}
        menuPortalTarget={document && document.body}
        cacheOptions
        className={classnames('react-select', className)}
        noOptionsMessage={() => t('form.autocomplete.no-results')}
        aria-label={name}
        styles={{
            ...zuprStyles({ isSmall }),
            ...styles,
        }}
        {...props}
        value={findOptionForValue(value, options)}
        options={options}
        components={{
            MultiValueRemove,
            DropdownIndicator,
            ClearIndicator,
            ...components,
        }}
    />
)

export default Select
