import React, { useCallback } from 'react'
import LinkChangeQuery from '../../../../shared/router/link-change-query'

export const FilterLink = ({ children, count, ...props }) => (
    <LinkChangeQuery {...props}>
        {children}
        {!!count && (
            <React.Fragment>
                {' '}
                <small>{`(${count})`}</small>
            </React.Fragment>
        )}
    </LinkChangeQuery>
)

const FilterLinks = ({
    buckets,
    aggregationKey,
    filterKey,
    formatLabel,
    value,
}) => {
    const addValue = useCallback(
        (toAdd) => {
            if (!value) return [toAdd]

            const values = value.split(',')

            if (values.includes(toAdd)) {
                return values
            }
            return [...values, toAdd]
        },
        [value]
    )

    const count = useCallback(
        (bucket) => {
            if (bucket[`reverse.${aggregationKey}`])
                return bucket[`reverse.${aggregationKey}`].doc_count
            return bucket.doc_count
        },
        [aggregationKey]
    )

    return (
        <React.Fragment>
            {buckets.map((bucket) => (
                <FilterLink
                    key={`${filterKey}.${bucket.key}`}
                    query={{
                        [filterKey]: addValue(bucket.key).join(','),
                    }}
                    count={count(bucket)}
                >
                    {formatLabel && formatLabel(bucket.key)}
                    {!formatLabel && bucket.key}
                </FilterLink>
            ))}
        </React.Fragment>
    )
}

export default FilterLinks
