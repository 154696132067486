import React, { useCallback, useContext } from 'react'
import { useRouter } from 'next/router'

import { t } from '@zupr/i18n'
import Trans from '../trans'

import RouteContext from '../../../context/route'

import '../../../../scss/react/filter/filters.scss'
import '../../../../scss/react/components/sorter-link.scss'

interface SortProps {
    choices: string[]
    defaultOrdering?: string
    ordering?: string
    noLabel?: boolean
}

const Sort = ({ ordering, choices, defaultOrdering, noLabel }: SortProps) => {
    const { query, replace } = useRouter()
    const { changeQuery } = useContext(RouteContext)

    const handleChange = useCallback(
        (ev) => replace(changeQuery({ ordering: ev.target.value })),
        [changeQuery, replace]
    )

    return (
        <div className="pagination-ordering">
            {!noLabel && (
                <label htmlFor="pagination-ordering">
                    <Trans label="Sort" />
                </label>
            )}
            <select
                name="pagination-ordering"
                onChange={handleChange}
                className="small"
                value={ordering || query.ordering || defaultOrdering}
            >
                {choices.map((key) => (
                    <option key={key} value={key}>
                        {t(key, 'filter')}
                    </option>
                ))}
            </select>
        </div>
    )
}

export default Sort
