import { t } from '@zupr/i18n'
import { ProductLocation } from '@zupr/types/fo'
import { useCallback, useContext } from 'react'

import RouteContext from '../../../context/route'
import { useShopper } from '../../../context/shopper'
import ShoppingListContext from '../../../context/shopping-list'
import StockIndicator from '../../../shared/product-location/stock/indicator/icon'
import StockLabel from '../../../shared/product-location/stock/indicator/label'
import useFeatures from '../../features/hooks/features'
import {
    useShoppingAddProductLocation,
    useShoppingListItem,
} from '../../features/shopping-list/hooks/item'
import { useDeliveryMethods } from '../../screens/checkout/hooks/shipping'
import { GridCheck } from '../grid/form'

import { ReactComponent as Plus } from '../../../../svg/plus.svg'

import '../../../../scss/react/product-location/product-location-stock.scss'

interface StockProps {
    onClick?: () => void
    productLocation: ProductLocation
}

const ProductLocationStock = ({ productLocation, onClick }: StockProps) => {
    const { trackEvent } = useContext(RouteContext)

    const { payAndCollect, reservation, shoppable } =
        useFeatures(productLocation)

    const { onOpenSidebar } = useContext(ShoppingListContext)

    const { onUpdate, quantity, isOnList, availableToAdd } =
        useShoppingListItem(productLocation)

    const onAdd = useShoppingAddProductLocation(productLocation)
    const { toggleShopperLocationForm, shippingDetails } = useShopper()

    const methods = useDeliveryMethods({
        zipcode: shippingDetails.zipcode,
        locationId: productLocation.location.id,
    })

    const handleAdd = useCallback(() => {
        if (onClick) onClick() // callback

        if (isOnList) {
            onUpdate({
                quantity: Math.min(quantity + 1, availableToAdd),
            })
        } else {
            onAdd()
            trackEvent({
                id: productLocation.location.id,
                action: 'addProductToShoppingList',
                context: productLocation.product.id,
            })
            trackEvent({
                id: productLocation.product.id,
                action: 'addProductToShoppingList',
                context: productLocation.location.id,
            })
        }

        onOpenSidebar()
    }, [
        availableToAdd,
        isOnList,
        onAdd,
        onClick,
        onOpenSidebar,
        onUpdate,
        productLocation.location.id,
        productLocation.product.id,
        quantity,
        trackEvent,
    ])

    const hasZipcode = !!shippingDetails.zipcode
    const hasDelivery = productLocation.allow_delivery
    const canDeliver = methods?.some((method) => method.canDeliver)

    return (
        <div className="product-location-stock">
            <p>
                <StockIndicator {...productLocation} />
                <strong>
                    <StockLabel {...productLocation} />
                </strong>
            </p>

            {shoppable && (
                <>
                    <button className="btn  btn-success" onClick={handleAdd}>
                        <Plus />
                        <span>{t('Add to cart')}</span>
                    </button>
                    <div>
                        <GridCheck
                            question={
                                productLocation.stock_prefs === 'call_for_stock'
                            }
                            checked={
                                productLocation.stock_prefs !== 'call_for_stock'
                            }
                        >
                            {t('In de winkel te koop', 'dutch')}
                        </GridCheck>
                        <GridCheck checked={reservation}>
                            {t('Reserveren', 'dutch')}
                        </GridCheck>
                        <GridCheck checked={payAndCollect}>
                            {t('Click & Collect', 'dutch')}
                        </GridCheck>
                        <GridCheck
                            checked={canDeliver}
                            question={hasDelivery && !hasZipcode}
                        >
                            {hasZipcode &&
                                hasDelivery &&
                                t(
                                    canDeliver
                                        ? 'Bezorgen op %{zipcode} beschikbaar'
                                        : 'Bezorgen op %{zipcode} niet beschikbaar',
                                    {
                                        zipcode: shippingDetails.zipcode,
                                    }
                                )}
                            {!hasZipcode && hasDelivery && `${t('Bezorgen')}*`}
                            {!hasDelivery && t('Bezorgen niet beschikbaar')}
                        </GridCheck>{' '}
                        {hasDelivery && !hasZipcode && (
                            <small>
                                {'* '}
                                <button
                                    className="inline link"
                                    onClick={() =>
                                        toggleShopperLocationForm(true)
                                    }
                                >
                                    {t(
                                        'Voer je postcode in voor beschikbaarheid'
                                    )}
                                </button>
                            </small>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default ProductLocationStock
