import { useItem, useList } from '@zupr/hooks/request-redux'
import { Product, ProductLocation } from '@zupr/types/fo'
import { formatSubtitle } from '@zupr/utils/product'
import { useCallback, useContext } from 'react'

import Buttons from './buttons'

import PriceAndUnit from '../../../shared/product-location/price-and-unit'
import Variations from '../../../shared/product/components/variations'

import GridBlock from '../grid/block'
import GridColumn from '../grid/column'
import GridPage from '../grid/page'

import ModalContext, { ModalProps } from '../../../context/modal'

import '../../../../scss/react/pages/location-product.scss'
import Carousel from '../../../shared/image/carousel'
interface Props extends ModalProps {
    productLocation: ProductLocation
}

const ProductLocationModal = ({ productLocation, onCloseModal }: Props) => {
    const { openModal } = useContext(ModalContext)

    const [product] = useItem<Product>({ url: productLocation.product.url })
    const [productVariations] = useList<Product>({
        url: 'fo/product',
        variables: {
            variation_uuid: product?.variation_uuid,
        },
        all: true,
        pause: !product?.variation_uuid,
    })

    const [availableVariations] = useList<ProductLocation>({
        url: 'fo/product-location',
        variables: {
            'location.id': productLocation.location.id,
            'product.variation_uuid': product?.variation_uuid,
        },
        all: true,
        pause: !product?.variation_uuid,
    })

    const handleVariation = useCallback(
        ({ id }) => {
            const productLocation = availableVariations.results.find(
                (productLocation) => productLocation.product.id === id
            )
            onCloseModal()
            openModal({
                closeButton: true,
                render: (props) => (
                    <ProductLocationModal
                        {...props}
                        productLocation={productLocation}
                    />
                ),
            })
        },
        [availableVariations?.results, onCloseModal, openModal]
    )

    const handleClickAdd = useCallback(() => {
        onCloseModal()
    }, [onCloseModal])

    if (!product) return null

    return (
        <GridPage leftWide className="location-product location-product-modal">
            <GridBlock>
                <Carousel
                    className="product-images"
                    images={product.images}
                    alt={product.title}
                    navigation
                    zoom
                    thumbnails
                />
            </GridBlock>
            <GridColumn>
                <GridBlock>
                    <div className="product-titles">
                        <div>
                            <h1>
                                {product?.title ||
                                    productLocation.product.title}
                            </h1>
                            <h2>
                                {formatSubtitle(
                                    product || productLocation.product
                                )}
                            </h2>
                        </div>
                    </div>
                    {product && (
                        <Variations
                            product={product}
                            variations={productVariations}
                            onVariation={handleVariation}
                            availableVariations={availableVariations}
                        />
                    )}
                    <div className="price-and-unit">
                        <PriceAndUnit {...productLocation} />
                    </div>

                    <Buttons
                        productLocation={productLocation}
                        onClick={handleClickAdd}
                    />
                </GridBlock>
            </GridColumn>
        </GridPage>
    )
}

export default ProductLocationModal
