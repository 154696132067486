import Link from 'next/link'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import RouteContext from '../../context/route'

export interface LinkChangeQueryProps {
    className?: string
    children?: React.ReactNode
    style?: React.CSSProperties
    onClick?: () => void
    query?: Record<string, any>
    href?: string
    replace?: boolean
}

const useScrollMemory = () => {
    const [scrollAtTop, setScrollAtTop] = useState(true)
    const [scrollMemory, setScrollMemory] = useState<number>()

    // watch scroll position for replace urls
    useEffect(() => {
        const handleScroll = () => {
            if (scrollAtTop && window.scrollY !== 0) setScrollAtTop(false)
            if (!scrollAtTop && window.scrollY === 0) setScrollAtTop(true)
        }
        handleScroll()
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [scrollAtTop, scrollMemory])

    useEffect(() => {
        if (!scrollMemory) return
        if (!scrollAtTop) return
        window.scrollTo(0, scrollMemory)
        setScrollMemory(undefined)
    }, [scrollAtTop, scrollMemory])

    return setScrollMemory
}

const LinkChangeQuery = ({
    className,
    children,
    style,
    onClick,
    query,
    href,
    replace,
}: LinkChangeQueryProps) => {
    const startMemory = useScrollMemory()

    const { changeQuery } = useContext(RouteContext)

    const route = changeQuery(query)

    const handleClick = useCallback(() => {
        // remember scroll position
        if (replace && window.scrollY > 0) {
            startMemory(window.scrollY)
        }
        // fire proxied onclick
        if (onClick) onClick()
    }, [onClick, replace, startMemory])

    return (
        <Link
            replace={replace}
            href={{
                ...route,
                pathname: href ?? route.pathname,
            }}
        >
            <a className={className} style={style} onClick={handleClick}>
                {children}
            </a>
        </Link>
    )
}

export default LinkChangeQuery
