import { useCallback, useContext } from 'react'

import ModalContext from '../../../../context/modal'
import Carousel from '../../../../shared/image/carousel'
import GridBlock from '../../../components/grid/block'
import GridPage from '../../../components/grid/page'

import '../../../../../scss/react/modal/image.scss'
import '../../../../../scss/react/pages/location-view.scss'
import Image from '../../../../shared/components/image'

const LocationImage = ({ image, images, index, alt }) => {
    const { openModal } = useContext(ModalContext)

    const handleOverlay = useCallback(() => {
        openModal({
            closeButton: true,
            className: 'modal-image',
            render: () => (
                <Carousel
                    alt={alt}
                    startIndex={index}
                    images={images}
                    navigation
                    thumbnails
                />
            ),
        })
    }, [alt, images, index, openModal])

    return (
        <button className="inline" onClick={handleOverlay}>
            <Image image={image} alt={`${alt}, ${image.tag}`} large />
        </button>
    )
}

const LocationImages = ({ location }) => (
    <GridPage className="location-images-grid">
        {location.images
            .sort((a, b) => a.tag >= b.tag)
            .map((image, index) => (
                <GridBlock>
                    <LocationImage
                        images={location.images}
                        image={image}
                        index={index}
                        alt={location.title}
                    />
                </GridBlock>
            ))}
    </GridPage>
)

export default LocationImages
