import { useRouter } from 'next/router'

import Trans from '../trans'

interface Props {
    count?: number
    offset?: number
    offsetKey?: string
    limit?: number
}

const Offset = ({ count, offset, limit, offsetKey }: Props) => {
    const { query } = useRouter()

    offsetKey = offsetKey || 'offset'

    limit = Number(query.limit || limit || 24)
    count = Number(query.count || count || 0)
    offset = Number(query[offsetKey] || offset || 0)

    return (
        <div className="offset">
            {!!count && (
                <Trans
                    label="%{start}-%{end} of %{count}"
                    values={{
                        count,
                        start: Number(count && offset) + 1 || 0,
                        end: Math.min(offset + limit, count),
                    }}
                />
            )}
        </div>
    )
}

export default Offset
