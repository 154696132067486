import { t } from '@zupr/i18n'
import { Location } from '@zupr/types/fo'
import React, { useContext } from 'react'

import RouteContext from '../../../context/route'
import Tooltip from '../../components/tooltip'
import ExternalLink from '../../router/link-external'

import '../../../../scss/react/components/action-links.scss'
import { ReactComponent as CalendarIcon } from '../../../../svg/calendar.svg'

interface PlannerLinkProps {
    location: Location
    className?: string
    onClick?: () => void
    children?: React.ReactNode
    iconOnly?: boolean
}

const PlannerLink = ({
    location,
    className,
    children,
    iconOnly,
    onClick,
}: PlannerLinkProps) => {
    const { trackEvent } = useContext(RouteContext)

    const handleClick = () => {
        trackEvent({
            id: location.id,
            action: 'clickPlanMeeting',
        })

        if (onClick) onClick()
    }

    return (
        <ExternalLink
            className={className}
            href={location.meeting_planner_url}
            onClick={handleClick}
            aria-label={t('Plan je afspraak', 'dutch')}
        >
            <span className="btn-social social-platform">
                <CalendarIcon />
            </span>
            {!iconOnly && (children || t('Plan je afspraak', 'dutch'))}
        </ExternalLink>
    )
}

export const PlannerAction = ({ ...props }: PlannerLinkProps) => (
    <Tooltip label={t('Plan je afspraak', 'dutch')}>
        <PlannerLink className="btn" iconOnly {...props} />
    </Tooltip>
)

export default PlannerLink
