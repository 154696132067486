import { useRouter } from 'next/router'
import { useCallback, useContext } from 'react'

import Trans from '../trans'

import RouteContext from '../../../context/route'

import '../../../../scss/react/components/sorter-link.scss'

interface Props {
    limit?: number
    limitKey?: string
}

const Limit = ({ limit, limitKey }: Props) => {
    const { query, push } = useRouter()
    const { changeQuery } = useContext(RouteContext)

    limitKey = limitKey || 'limit'
    limit = Number(query[limitKey] || limit || 24)

    const onChange = useCallback(
        (ev) => push(changeQuery({ [limitKey]: ev.target.value })),
        [changeQuery, limitKey, push]
    )

    return (
        <div className="pagination-limit">
            <label htmlFor="limit-selector">
                <Trans label="Show" />
            </label>
            <select
                name="limit-selector"
                className="small"
                value={limit}
                onChange={onChange}
            >
                <option value={12}>{`12`}</option>
                <option value={24}>{`24`}</option>
                <option value={48}>{`48`}</option>
                {/* <option value={'∞'}>{trans({ label: "pagination.limit.all" })}</option> */}
            </select>
        </div>
    )
}

Limit.defaultProps = {
    limit: 24,
}

export default Limit
