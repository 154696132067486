import React from 'react'

interface AnchorProps {
    id: string
    offset: number
}

const Anchor = ({ id, offset }: AnchorProps) => (
    <div key={id} id={id} style={{ position: 'relative', top: offset * -1 }} />
)

export default Anchor
