import React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { Marker, MarkerProps } from '@react-google-maps/api'
import { normalizeLatLng } from '@zupr/utils/geolocation'

const DotMarker = ({ backgroundColor }) => (
    <svg
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <circle id="path-1" cx="3" cy="3" r="3"></circle>
            <filter
                x="-150.0%"
                y="-116.7%"
                width="400.0%"
                height="400.0%"
                filterUnits="objectBoundingBox"
                id="filter-2"
            >
                <feMorphology
                    radius="2"
                    operator="dilate"
                    in="SourceAlpha"
                    result="shadowSpreadOuter1"
                ></feMorphology>
                <feOffset
                    dx="0"
                    dy="2"
                    in="shadowSpreadOuter1"
                    result="shadowOffsetOuter1"
                ></feOffset>
                <feGaussianBlur
                    stdDeviation="2"
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                ></feGaussianBlur>
                <feComposite
                    in="shadowBlurOuter1"
                    in2="SourceAlpha"
                    operator="out"
                    result="shadowBlurOuter1"
                ></feComposite>
                <feColorMatrix
                    values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0"
                    type="matrix"
                    in="shadowBlurOuter1"
                ></feColorMatrix>
            </filter>
        </defs>
        <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g id="Oval" transform="translate(6.000000, 4.000000)">
                <use
                    fill="black"
                    fillOpacity="1"
                    filter="url(#filter-2)"
                    xlinkHref="#path-1"
                ></use>
                <circle
                    stroke="#FFFFFF"
                    strokeWidth="2"
                    fill={backgroundColor}
                    fillRule="evenodd"
                    cx="3"
                    cy="3"
                    r="4"
                ></circle>
            </g>
        </g>
    </svg>
)

const InactiveMarker = ({ backgroundColor, shopping }) => (
    <svg
        width="38px"
        height="43px"
        viewBox="0 0 38 43"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                d="M5,0 L25,0 C27.7614237,-5.07265313e-16 30,2.23857625 30,5 L30,25 C30,27.7614237 27.7614237,30 25,30 L19,30 L19,30 L15,35 L11,30 L5,30 C2.23857625,30 3.38176876e-16,27.7614237 0,25 L0,5 C-3.38176876e-16,2.23857625 2.23857625,-1.26909153e-15 5,0 Z"
                id="path-1"
            ></path>
            <filter
                x="-23.3%"
                y="-14.3%"
                width="146.7%"
                height="140.0%"
                filterUnits="objectBoundingBox"
                id="filter-2"
            >
                <feOffset
                    dx="0"
                    dy="2"
                    in="SourceAlpha"
                    result="shadowOffsetOuter1"
                ></feOffset>
                <feGaussianBlur
                    stdDeviation="2"
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                ></feGaussianBlur>
                <feColorMatrix
                    values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0"
                    type="matrix"
                    in="shadowBlurOuter1"
                ></feColorMatrix>
            </filter>
        </defs>
        <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g id="Group" transform="translate(4.000000, 2.000000)">
                <g id="background">
                    <use
                        fill="black"
                        fillOpacity="1"
                        filter="url(#filter-2)"
                        xlinkHref="#path-1"
                    ></use>
                    <use
                        fill="#FFFFFF"
                        fillRule="evenodd"
                        xlinkHref="#path-1"
                    ></use>
                </g>
                <rect
                    id="background"
                    fill={backgroundColor}
                    opacity="0.0799999982"
                    x="2"
                    y="2"
                    width="26"
                    height="26"
                    rx="4"
                ></rect>
                {shopping && (
                    <path
                        d="M15.0015662,22.0000492 C17.1477753,22.0000492 19.3085845,21.8104652 21.4255935,21.4312972 C21.7905951,21.3729637 22.0387961,21.0229625 21.994996,20.6583778 C21.6153943,17.9458683 21.2503928,15.1750252 20.8707912,12.3458486 C20.826991,11.9958474 20.5203897,11.7333465 20.1699882,11.7333465 L17.8047781,11.7333465 L17.8047781,10.9750105 C17.8047781,9.34167138 16.5491728,8 15.0015662,8 C13.4539596,8 12.1983542,9.34167138 12.1983542,10.9750105 L12.1983542,11.7333465 L9.83314414,11.7333465 C9.48274265,11.7333465 9.17614135,11.9958474 9.13234116,12.3458486 C8.75273954,15.1750252 8.38773799,17.9458683 8.00813637,20.6583778 C7.94973612,21.0229625 8.21253724,21.3729637 8.57753879,21.4312972 C10.6945478,21.8104652 12.855357,22.0000492 15.0015662,22.0000492 Z M17.5720307,15.5015842 L17.6534067,15.5164064 C17.903312,15.5925541 18.0503152,15.866686 17.9841638,16.1255884 C17.6607568,17.2982638 16.6905361,18.1891925 15.5880126,18.4328653 C15.3264525,18.4776218 15.1304483,18.5 15,18.5 C14.8691094,18.5 14.6731052,18.4776218 14.4119874,18.4328653 C13.3094639,18.1891925 12.3392432,17.2982638 12.0158362,16.1179736 C11.9496848,15.8590713 12.096688,15.5925541 12.3465933,15.5164064 C12.5891485,15.4478734 12.8537541,15.6001689 12.9272557,15.8590713 C13.1918614,16.8337625 14.0738802,17.5114775 15,17.5114775 C15.9261198,17.5114775 16.8081386,16.8337625 17.0727443,15.8590713 C17.1462459,15.6001689 17.4108515,15.4478734 17.6534067,15.5164064 Z M15.0015662,9.35625477 C15.7753695,9.35625477 16.4031721,10.1145908 16.4031721,10.9750105 L16.4031721,11.7333465 L13.5999602,11.7333465 L13.5999602,10.9750105 C13.5999602,10.1145908 14.2277629,9.35625477 15.0015662,9.35625477 Z"
                        id="bag"
                        fill={backgroundColor}
                        fillRule="nonzero"
                    ></path>
                )}
            </g>
        </g>
    </svg>
)

const ActiveMarker = ({ backgroundColor, shopping }) => (
    <svg
        width="46px"
        height="51px"
        viewBox="0 0 46 51"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                d="M5,0 L33,0 C35.7614237,-5.07265313e-16 38,2.23857625 38,5 L38,33 C38,35.7614237 35.7614237,38 33,38 L23,38 L23,38 L19,43 L15,38 L5,38 C2.23857625,38 -1.43817996e-15,35.7614237 0,33 L0,5 C-3.38176876e-16,2.23857625 2.23857625,-3.80913106e-16 5,0 Z"
                id="path-1"
            ></path>
            <filter
                x="-18.4%"
                y="-11.6%"
                width="136.8%"
                height="132.6%"
                filterUnits="objectBoundingBox"
                id="filter-2"
            >
                <feOffset
                    dx="0"
                    dy="2"
                    in="SourceAlpha"
                    result="shadowOffsetOuter1"
                ></feOffset>
                <feGaussianBlur
                    stdDeviation="2"
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                ></feGaussianBlur>
                <feColorMatrix
                    values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0"
                    type="matrix"
                    in="shadowBlurOuter1"
                ></feColorMatrix>
            </filter>
        </defs>
        <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g id="pin" transform="translate(4.000000, 2.000000)">
                <g id="background">
                    <use
                        fill="black"
                        fillOpacity="1"
                        filter="url(#filter-2)"
                        xlinkHref="#path-1"
                    ></use>
                    <use
                        fill="#FFFFFF"
                        fillRule="evenodd"
                        xlinkHref="#path-1"
                    ></use>
                </g>
                <path
                    d="M6,2 L32,2 C34.209139,2 36,3.790861 36,6 L36,32 C36,34.209139 34.209139,36 32,36 L6,36 C3.790861,36 2,34.209139 2,32 L2,6 C2,3.790861 3.790861,2 6,2 Z"
                    id="background"
                    fill={backgroundColor}
                ></path>
                {shopping && (
                    <path
                        d="M19.0020136,28.0000633 C21.7614254,28.0000633 24.5396087,27.7563124 27.2614774,27.2688107 C27.7307651,27.1938105 28.0498808,26.7438089 27.9935662,26.2750572 C27.505507,22.787545 27.0362193,19.2250324 26.5481601,15.5875196 C26.4918455,15.1375181 26.0976439,14.8000169 25.6471277,14.8000169 L22.6061433,14.8000169 L22.6061433,13.8250135 C22.6061433,11.7250061 20.9917935,10 19.0020136,10 C17.0122337,10 15.397884,11.7250061 15.397884,13.8250135 L15.397884,14.8000169 L12.3568996,14.8000169 C11.9063834,14.8000169 11.5121817,15.1375181 11.4558672,15.5875196 C10.967808,19.2250324 10.4985203,22.787545 10.010461,26.2750572 C9.93537501,26.7438089 10.2732622,27.1938105 10.7425499,27.2688107 C13.4644186,27.7563124 16.2426019,28.0000633 19.0020136,28.0000633 Z M21.6649569,20.1045202 C21.759459,19.7716457 22.0996662,19.5758372 22.4115229,19.6639511 C22.7328298,19.7618553 22.9218338,20.1143106 22.836782,20.4471851 C22.4209731,21.9549106 21.1735464,23.1003903 19.7560162,23.4136839 C19.4197247,23.471228 19.1677193,23.5 19,23.5 C18.8317121,23.5 18.5797067,23.471228 18.2439838,23.4136839 C16.8264536,23.1003903 15.5790269,21.9549106 15.163218,20.4373947 C15.0781662,20.1045202 15.2671702,19.7618553 15.5884771,19.6639511 C15.9003338,19.5758372 16.240541,19.7716457 16.3350431,20.1045202 C16.6752503,21.3576946 17.8092746,22.2290425 19,22.2290425 C20.1907254,22.2290425 21.3247497,21.3576946 21.6649569,20.1045202 Z M19.0020136,11.7437561 C19.9969036,11.7437561 20.8040785,12.7187596 20.8040785,13.8250135 L20.8040785,14.8000169 L17.1999488,14.8000169 L17.1999488,13.8250135 C17.1999488,12.7187596 18.0071237,11.7437561 19.0020136,11.7437561 Z"
                        id="bag"
                        fill="#FFFFFF"
                        fillRule="nonzero"
                    ></path>
                )}
            </g>
        </g>
    </svg>
)

DotMarker.defaultProps = {
    backgroundColor: '#FF901F',
}
InactiveMarker.defaultProps = {
    backgroundColor: '#FF901F',
}
ActiveMarker.defaultProps = {
    backgroundColor: '#FF901F',
}

interface LocationMarkerProps extends MarkerProps {
    zIndex?: number
    active?: boolean
    dot?: boolean
    position: any
}

const LocationMarker = ({
    zIndex,
    active,
    dot,
    position,
    ...props
}: LocationMarkerProps) => {
    if (!position) return null

    const icon = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
        renderToStaticMarkup(
            dot ? (
                <DotMarker />
            ) : active ? (
                <ActiveMarker shopping />
            ) : (
                <InactiveMarker shopping />
            )
        )
    )}`

    return (
        <Marker
            zIndex={!active ? (dot ? 1 : 2) : dot ? 3 : 4}
            position={normalizeLatLng(position)}
            {...props}
            icon={icon}
        />
    )
}

export default LocationMarker
