import { Location } from '@zupr/types/fo'

import EmailLink from '../../../../shared/location/links/email'
import PhoneLink from '../../../../shared/location/links/phone'
import PlannerLink from '../../../../shared/location/links/planner'
import SocialLink, {
    WhatsappLink,
} from '../../../../shared/location/links/social'
import WebsiteLink from '../../../../shared/location/links/website'
import GridBlock from '../../../components/grid/block'

interface ContactProps {
    location: Location
}

const ContactBlock = ({ location }: ContactProps) => (
    <GridBlock title="Neem contact op">
        <p>
            {location.phone && (
                <>
                    <PhoneLink className="location-link" location={location} />
                    <br />
                </>
            )}
            {location.whatsapp && (
                <>
                    <WhatsappLink location={location} />
                    <br />
                </>
            )}
            {location.email && (
                <>
                    <EmailLink className="location-link" location={location} />
                    <br />
                </>
            )}
            {location.website && (
                <>
                    <WebsiteLink
                        className="location-link"
                        location={location}
                    />
                    <br />
                </>
            )}
            {location.facebook && (
                <>
                    <SocialLink platform="facebook" location={location} />
                    <br />
                </>
            )}
            {location.instagram && (
                <>
                    <SocialLink platform="instagram" location={location} />
                    <br />
                </>
            )}
            {location.meeting_planner_url && (
                <PlannerLink className="location-link" location={location} />
            )}
        </p>
    </GridBlock>
)

export default ContactBlock
