import classnames from 'classnames'
import React from 'react'
import Link from '../../router/link-change-query'

import '../../../../scss/react/components/pagination.scss'

interface PageLinkProps {
    page: number
    limit: number
    active?: boolean
    offsetKey: string
}

const PageLink = ({ page, limit, active, offsetKey }: PageLinkProps) => (
    <Link
        className={classnames({ active })}
        query={{
            [offsetKey]: limit * page > 0 ? limit * page : null, // no offset if limit is 0
        }}
    >
        {page + 1}
    </Link>
)

interface Props {
    pages: number
    limit: number
    currentPage: number
    offsetKey?: string
}

const Pages = ({ pages, limit, currentPage, offsetKey }: Props) => {
    offsetKey = offsetKey || 'offset'

    // show all
    if (pages <= 7) {
        return (
            <React.Fragment>
                {[...Array(pages)].map((_, page) => (
                    <PageLink
                        key={`pagelink-${page}`}
                        active={page === currentPage}
                        page={page}
                        limit={limit}
                        offsetKey={offsetKey}
                    />
                ))}
            </React.Fragment>
        )
    }

    // number of pages to show between the gaps
    const numberOfPages = 5

    // show first page link
    const showFirstPage = currentPage > 2

    // show last page link
    const showLastPage = pages - currentPage > numberOfPages + 2

    // start list at 0 but at least 5 pages from the end
    const startAtPage = showFirstPage
        ? Math.min(currentPage, pages - numberOfPages)
        : 0

    // how many pages do we need to show
    // 5 in the middle 1, 2 extra depending how close to the edge
    // cant be negative pages from the edge
    const pagesFromTheEdge = Math.max(
        Math.min(currentPage, pages - currentPage - numberOfPages),
        0
    )
    const pagesToShow =
        pagesFromTheEdge < 3 ? numberOfPages + pagesFromTheEdge : numberOfPages

    return (
        <React.Fragment>
            {showFirstPage && (
                <React.Fragment>
                    <PageLink page={0} limit={limit} offsetKey={offsetKey} />
                    <span className="gap">{'…'}</span>
                </React.Fragment>
            )}

            {[...Array(pagesToShow)].map((_, page) => (
                <PageLink
                    key={`page-${page}`}
                    active={startAtPage + page === currentPage}
                    page={startAtPage + page}
                    limit={limit}
                    offsetKey={offsetKey}
                />
            ))}

            {showLastPage && (
                <React.Fragment>
                    <span className="gap">{'…'}</span>
                    <PageLink
                        page={pages - 1}
                        limit={limit}
                        offsetKey={offsetKey}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default Pages
