import React, { useContext, useMemo } from 'react'
import { useRouter } from 'next/router'
import { Aggregations, Variables } from '@zupr/types/fo'
import { filterKeys, aggregationKeys } from '@zupr/next/helpers/products'

import Trans from '../../../../shared/components/trans'

import RouteContext from '../../../../context/route'
import SwitchField from '../../../../shared/form/fields/switch'

interface Props {
    aggregations: Aggregations
    variables: Variables
}

const DiscountFilter = ({ aggregations, variables }: Props) => {
    const { push } = useRouter()

    const { changeQuery } = useContext(RouteContext)

    const hasDiscountedProducts: boolean = useMemo(() => {
        return !!aggregations.data?.[aggregationKeys.discount]?.buckets?.[0]?.[
            `reverse.${aggregationKeys.discount}`
        ].doc_count
    }, [aggregations.data])

    const discountField = {
        name: 'discount',
        value: variables[filterKeys.discount] === 'true',
        setValue: () => {
            push(
                changeQuery({
                    discount: variables[filterKeys.discount] ? null : 'true',
                })
            )
        },
    }

    if (!hasDiscountedProducts && !variables[filterKeys.discount]) return null

    return (
        <div className="sidebar-filter">
            <div className="filter-header">
                <label htmlFor="discount">
                    <Trans label="Acties" dutch />
                </label>
                <SwitchField field={discountField} />
            </div>
        </div>
    )
}

export default DiscountFilter
