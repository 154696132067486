import iso4217 from 'iso4217'

const currencies = {}
const topCurrencies = [
    'EUR',
    'USD',
    'JPY',
    'GBP',
    'AUD',
    'CAD',
    'CHF',
    'CNY',
    'SEK',
    'NZD',
    'MXN',
    'SGD',
    'HKD',
    'NOK',
    'KRW',
    'TRY',
    'RUB',
    'INR',
    'BRL',
    'ZAR',
]

const sortedCurrencyCodes = [...topCurrencies]

Object.keys(iso4217)
    .map(num => iso4217[num])
    .filter(currency => currency && currency.Symbol && currency.Symbol !== '')
    .sort((a, b) => a.Symbol > b.Symbol)
    .forEach(currency => {
        if (!topCurrencies.includes(currency.Code))
            sortedCurrencyCodes.push(currency.Code)
        currencies[currency.Code] = currency
    })

export default currencies
export { topCurrencies, sortedCurrencyCodes }

export const choices = sortedCurrencyCodes.map(code => ({
    value: currencies[code].Code,
    display_name: `${currencies[code].Symbol} / ${currencies[code].Code.toLowerCase()}`,
    symbol: currencies[code].Symbol,
    name: currencies[code].Currency
}))

export const currencyToSymbol = code => currencies[code] && currencies[code].Symbol
